.cards {
  margin: 0.5vw;
  display: flex;
  flex-wrap: wrap;
}

.card {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vw;
  height: 12vw;
  font-size: 4vw;
  background-color: #ddd;
  margin-bottom: 1vw;
  margin-right: 1vw;
  border-radius: 0.5vw;
}

.recoding {
  background-color: #fdd;
}

.point {
  position: fixed;
  right: 1vw;
  bottom: 1vw;
  font-size: 4vw;
}

.point img {
  width: 2vw;
}
